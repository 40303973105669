import React from "react";
import { MapView } from '@aws-amplify/ui-react';
import { Box, Fab } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { NavigationControl, ViewStateChangeEvent, MapRef } from "react-map-gl";
import Pin from "../Pin";
import DoneIcon from '@mui/icons-material/Done';


interface Props {
    initialLat: number;
    initialLng: number;
    updateLocation: (lat: number, lng: number) => void;
    onClose: () => void
}


const AddLocation: React.FC<Props> = (props: Props) => {
    const { onClose, initialLat, initialLng, updateLocation } = props;
    const mapRef = React.useRef<MapRef | null>(null);
    const moveMap = (event: ViewStateChangeEvent) => {
        updateLocation(event.viewState.latitude, event.viewState.longitude)
    }


    const done = () => {
        onClose();
        // const lngLat = mapRef.current?.getCenter();
        // if (lngLat) {
        //     const { lat, lng } = lngLat;
        //     onClose();
        //     updateLocation(lat, lng)
        // }
    }


    return (
        <Box >
            <Pin />
            <MapView
                initialViewState={{
                    latitude: initialLat,
                    longitude: initialLng,
                    zoom: 12,
                }}
                style={{ overflow: "hidden" }}
                reuseMaps={true}
                ref={mapRef}
                onDragEnd={moveMap}
            >
                <NavigationControl />
            </MapView>
            <Fab sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 20,
                left: 'auto',
                position: 'fixed'
            }}
                color="primary"
                onClick={() => done()}>
                <DoneIcon />
            </Fab>
        </Box>
    )
}

export default AddLocation