import React from 'react';
import './App.css';
import { Amplify, Storage, AuthModeStrategyType } from 'aws-amplify';
//import { withAuthenticator } from '@aws-amplify/ui-react';
import awsmobile from './aws-exports.js';
import TrackingPage from "./pages/TrackingPage";
import EntryPage from "./pages/EntryPage";
import Login from "./components/Login";
import RequireAuth from "./components/RequireAuth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import Test from './pages/Test';
import LandingPage from './pages/LandingPage';
import NavBar from "./components/NavBar";
Amplify.configure({
  ...awsmobile,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  },
  Auth: { mandatorySignIn: false }
});
Storage.configure({ level: 'protected' });

function App() {
  return (<div className="App">
    <Authenticator.Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><NavBar /><LandingPage /></>} />
          <Route path="/entry" element={
            <RequireAuth>
              <EntryPage />
            </RequireAuth>
          } />
          <Route path="/track/:bugId" element={<TrackingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </BrowserRouter>
    </Authenticator.Provider>
  </div>)

}

//export default withAuthenticator(App, { hideSignUp: true });
export default App;
