/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      title
      note
      owner
      coords {
        lat
        long
      }
      createdAt
      images
      tags {
        items {
          id
          noteID
          tagID
          note {
            id
            title
            note
            owner
            createdAt
            images
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tag {
            id
            label
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      title
      note
      owner
      coords {
        lat
        long
      }
      createdAt
      images
      tags {
        items {
          id
          noteID
          tagID
          note {
            id
            title
            note
            owner
            createdAt
            images
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tag {
            id
            label
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      title
      note
      owner
      coords {
        lat
        long
      }
      createdAt
      images
      tags {
        items {
          id
          noteID
          tagID
          note {
            id
            title
            note
            owner
            createdAt
            images
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tag {
            id
            label
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      label
      notes {
        items {
          id
          noteID
          tagID
          note {
            id
            title
            note
            owner
            createdAt
            images
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tag {
            id
            label
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      label
      notes {
        items {
          id
          noteID
          tagID
          note {
            id
            title
            note
            owner
            createdAt
            images
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tag {
            id
            label
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      label
      notes {
        items {
          id
          noteID
          tagID
          note {
            id
            title
            note
            owner
            createdAt
            images
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          tag {
            id
            label
            owner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNoteTags = /* GraphQL */ `
  mutation CreateNoteTags(
    $input: CreateNoteTagsInput!
    $condition: ModelNoteTagsConditionInput
  ) {
    createNoteTags(input: $input, condition: $condition) {
      id
      noteID
      tagID
      note {
        id
        title
        note
        owner
        coords {
          lat
          long
        }
        createdAt
        images
        tags {
          items {
            id
            noteID
            tagID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        label
        notes {
          items {
            id
            noteID
            tagID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateNoteTags = /* GraphQL */ `
  mutation UpdateNoteTags(
    $input: UpdateNoteTagsInput!
    $condition: ModelNoteTagsConditionInput
  ) {
    updateNoteTags(input: $input, condition: $condition) {
      id
      noteID
      tagID
      note {
        id
        title
        note
        owner
        coords {
          lat
          long
        }
        createdAt
        images
        tags {
          items {
            id
            noteID
            tagID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        label
        notes {
          items {
            id
            noteID
            tagID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteNoteTags = /* GraphQL */ `
  mutation DeleteNoteTags(
    $input: DeleteNoteTagsInput!
    $condition: ModelNoteTagsConditionInput
  ) {
    deleteNoteTags(input: $input, condition: $condition) {
      id
      noteID
      tagID
      note {
        id
        title
        note
        owner
        coords {
          lat
          long
        }
        createdAt
        images
        tags {
          items {
            id
            noteID
            tagID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      tag {
        id
        label
        notes {
          items {
            id
            noteID
            tagID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
