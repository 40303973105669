import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react"

type RequireAuthProps = {
    children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = (props: RequireAuthProps) => {
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);
    if (route !== "authenticated") {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return props.children;

};

export default RequireAuth;