import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import React, { useState, useEffect } from "react";
import { Marker, Popup } from "react-map-gl";
import { Storage } from "aws-amplify";
import { Card, CardContent, CardMedia, Divider, CardActions } from '@mui/material';
import { format } from "date-fns";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Carousel from "react-material-ui-carousel";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { NoteTags } from "../../models";

interface MarkerWithPopupProps {
    note: any;
    id: number;
    isFirst?: boolean;
    flyToNext: (current: number) => void;
    isAuthenticated?: boolean;
    onDelete: (noteId: string) => void;
    triggerOpen: boolean;
}

interface ImageUrls { url: string, alt: string }

const MarkerWithPopup: React.FC<MarkerWithPopupProps> = (props: MarkerWithPopupProps) => {
    const [showPopup, setShowPopup] = useState(false);
    const [imageUrls, setImageUrls] = useState<Array<ImageUrls>>([]);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const { note, id, isFirst, flyToNext, isAuthenticated, onDelete, triggerOpen } = props;

    useEffect(() => {
        setShowPopup(triggerOpen);
    }, [triggerOpen])

    useEffect(() => {

        const getImagesFunc = async () => {
            const imgUrls: Array<ImageUrls> = [];
            // if (note.images != undefined && note.images.length > 0) {
            //     await Promise.all(note.images?.forEach(async (f: string) => {
            //         imgUrls.push(await Storage.get(f, { level: "protected" }));
            //     }));
            // }
            if (note.images && note.images.length > 0) {
                for (let i in note.images) {
                    const imageParts = note.images[i].split("/")
                    if (imageParts.length === 2) {
                        const url = await Storage.get(imageParts[1], { level: "protected", identityId: imageParts[0] })
                        imgUrls.push({ url: url, alt: "something" });
                    }
                }

            }
            setImageUrls(imgUrls);
        }
        getImagesFunc();
    }, [note])

    const fly = (current: number) => {
        setShowPopup(false);
        flyToNext(current);
    }

    const deleteNote = async () => {
        setDeleteOpen(false);
        setShowPopup(false);
        await onDelete(note.id);
    }

    const handleMarkerClick = ({ originalEvent }: any) => {
        originalEvent.stopPropagation();
        setShowPopup(true);
    }
    return (<React.Fragment key={id}>
        <Marker
            latitude={note.coords.lat}
            longitude={note.coords.long}
            onClick={handleMarkerClick}
            color={id === 0 ? "gold" : isFirst ? "green" : "#3FB1CE"}
        />
        {showPopup && (
            <Popup
                closeOnClick={true}
                latitude={note.coords.lat}
                longitude={note.coords.long}
                offset={{ bottom: [0, -40] }}
                onClose={() => setShowPopup(false)}>
                <Card sx={{ maxWidth: 345, maxHeight: 600 }} elevation={0}>
                    {imageUrls?.length > 0 && <Carousel>
                        {imageUrls.map((img: ImageUrls, key: number) => (
                            <CardMedia key={key} component="img" height="200" image={img.url} alt={img.alt} />
                        )
                        )}
                    </Carousel>}
                    <CardContent>
                        <Grid container >
                            <Grid item xs={11}>
                                <Typography component="div" variant="h5" >{note.title}</Typography>
                            </Grid>
                            {isAuthenticated && <Grid item xs={1}>
                                <IconButton aria-label="settings" onClick={() => setDeleteOpen(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>}
                        </Grid>
                        <Typography variant="caption">{format(new Date(note.createdAt), "dd/MM/yyyy")}</Typography>
                        <Divider />
                        <Typography variant="body2" sx={{ height: 100, overflowY: "auto" }}>{note.note}</Typography>
                    </CardContent>
                    <CardActions>
                        <Grid container flexDirection="column">
                            <Grid item>
                                {note.tags?.items?.map((t: NoteTags, i: number) => (<Chip key={i} label={t.tag.label} />))}
                            </Grid>
                            <Grid item >
                                {!isFirst && <Button onClick={() => fly(id + 1)}>Previous</Button>}
                                {id !== 0 && <Button onClick={() => fly(id - 1)}>Next</Button>}
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
                <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                    <DialogTitle>Are you sure you want to delete this note?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setDeleteOpen(false)}>No</Button>
                        <Button onClick={deleteNote}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </Popup>
        )}
    </React.Fragment>)
}

export default MarkerWithPopup;
