import React, { useState } from "react";
import { Button, Card, CardContent, CardHeader, CardMedia, Avatar, Divider, Grid, IconButton, CardActions, Chip } from '@mui/material';
import { red } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Carousel from "react-material-ui-carousel";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";



const Test = () => {

    const [deleteOpen, setDeleteOpen] = useState(false);

    let note = {
        title: "Off To Scotland",
        note: "Right, so, that's it. We're all packed up, van filled top to bottom, baby secured, traffic checked time to head off. Wish us luck!\n\n Right some more text to see if we can make this thing scroll a bit further. Not convinced. Need to see it on the popup too but at least this will do the job for now I should think!",
        tags: ["vanlife", "start"],
        createdAt: "2022-09-01T17:26:00.000Z",
        images: ["http://localhost:3000/image.JPG", "http://localhost:3000/image2.JPG", "http://localhost:3000/image3.JPG", "http://localhost:3000/image4.JPG", "http://localhost:3000/image5.JPG"]
    }

    const CardDisplay = () => (
        <Card sx={{ maxWidth: 345, maxHeight: 550 }}>
            <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="note">P</Avatar>}
                title={note.title}
                subheader={format(new Date(note.createdAt), "dd/MM/yyyy")}
                action={
                    <IconButton aria-label="settings" onClick={() => setDeleteOpen(true)}>
                        <DeleteIcon />
                    </IconButton>
                } />
            <Carousel>
                {note.images.map((img: string, key: number) => (
                    <CardMedia key={key} component="img" height="200" image={img} alt="myImage" />
                ))}
            </Carousel>
            <CardContent>
                <Typography component="div" variant="body2" sx={{ height: 100, overflowY: "auto" }}>{note.note}</Typography>
            </CardContent>
            <CardActions>
                <Grid container flexDirection="column">
                    <Grid item>
                        {note.tags.map((t: string, i: number) => (<Chip key={i} label={t} />))}
                    </Grid>
                    <Grid item >
                        <Button>Previous</Button>
                        <Button>Next</Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )

    const CardDisplayTwo = () => (
        <Card sx={{ maxWidth: 345, maxHeight: 550 }}>
            <Carousel>
                {note.images.map((img: string, key: number) => (
                    <CardMedia key={key} component="img" height="200" image={img} alt="myImage" />
                ))}
            </Carousel>
            <CardContent>
                <Grid container >
                    <Grid item xs={11}>
                        <Typography component="div" variant="h5" >{note.title}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="settings" onClick={() => setDeleteOpen(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Typography variant="caption">{format(new Date(note.createdAt), "dd/MM/yyyy")}</Typography>
                <Divider />
                <Typography variant="body2" sx={{ height: 100, overflowY: "auto" }}>{note.note}</Typography>
            </CardContent>
            <CardActions>
                <Grid container flexDirection="column">
                    <Grid item>
                        {note.tags.map((t: string, i: number) => (<Chip key={i} label={t} />))}
                    </Grid>
                    <Grid item >
                        <Button>Previous</Button>
                        <Button>Next</Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )

    return (<Grid container spacing={2} display="flex">
        <Grid item>
            <CardDisplay />
        </Grid>
        <Grid item>
            <CardDisplayTwo />
        </Grid>
        <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
            <DialogTitle>Are you sure you want to delete this note?</DialogTitle>
            <DialogActions>
                <Button>No</Button>
                <Button>Yes</Button>
            </DialogActions>
        </Dialog>
    </Grid >)

}

export default Test;