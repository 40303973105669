import { Authenticator, View, useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

interface LoginLocationState {
    from: {
        pathname: string;
    }
}

const Login: React.FC = () => {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const locationState = location.state as LoginLocationState;
    let from = locationState?.from?.pathname || "/";

    useEffect(() => {
        if (route === "authenticated") {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from])

    return (
        <View>
            <Authenticator hideSignUp={true}></Authenticator>
        </View>
    )
}

export default Login;
