import React, { useState } from "react"
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { Note } from "../models";
import { listNotes } from "../graphql/queries";
import { ListNotesQuery } from "../API";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "../components/hooks/useAuth";

const LandingPage = () => {
    const [account, setAccount] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const authenticated = useAuth();

    const go = async () => {
        setLoading(true);
        const notesResponse = (await API.graphql({
            query: listNotes,
            authMode: authenticated ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
            variables: {
                filter: {
                    owner: {
                        eq: account.toLowerCase().replace(" ", "") ?? ""
                    }
                }
            }
        })) as { data: ListNotesQuery };
        setLoading(false);
        const notes = notesResponse.data.listNotes?.items as Array<Note>;
        if (!notes || notes.length === 0) {
            setShowAlert(true);
            return;
        }
        navigate(`/track/${account.toLowerCase().replace(" ", "")}`);
    }

    return <Grid container flexDirection="column" display="flex" justifyContent="center" alignItems="center">
        <Grid item>
            <img src="/logo-text.png" alt="travelbug-logo" width="300vw" height="300vh" />
        </Grid>
        <Grid item>
            {loading && <CircularProgress />}
            <TextField error={showAlert} helperText={showAlert && "Unable to find anything for that user"} label="Who are you looking for?" value={account} onChange={(e) => { setAccount(e.target.value); setShowAlert(false); }} />
        </Grid>
        <Grid item>
            <Button disabled={!account || showAlert} onClick={() => go()} variant="contained">Go</Button>
        </Grid>
    </Grid>
}

export default LandingPage;