import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TagTypes } from "../../types/Model";
import TextField from "@mui/material/TextField";

interface ChipAutoCompleteProps {
  tags: Array<TagTypes>;
  updateSelected: (chips: Array<TagTypes>) => void;
}

const filter = createFilterOptions<TagTypes>();

const ChipAutocomplete: React.FC<ChipAutoCompleteProps> = (
  props: ChipAutoCompleteProps
) => {
  return (
    <Autocomplete
      sx={{ margin: 1 }}
      multiple
      id="tags"
      options={props.tags}
      getOptionLabel={(option) => {
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.label;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      onChange={(event, newValue) => {
        //TODO doesn't work :(
        newValue.forEach((v) => {
          if (v.inputValue) {
            v.label = v.inputValue;
          }
        });
        props.updateSelected(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Tags"
          placeholder="Tags"
        />
      )}
    />
  );
};

export default ChipAutocomplete;
