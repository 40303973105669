// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Note, Trip, Tag, NoteTags, Coordinates } = initSchema(schema);

export {
  Note,
  Trip,
  Tag,
  NoteTags,
  Coordinates
};