import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom"
import useAuth from "../hooks/useAuth";


const NavBar = () => {

    const auth: boolean = useAuth();

    return <AppBar position="static" color="transparent" sx={{ boxShadow: "none" }}>
        <Toolbar sx={{ justifyContent: "flex-end" }}>
            {
                auth ?
                    <IconButton component={Link} to={"/entry"} size="large" aria-label="Enter Note"><EditIcon /></IconButton>
                    : <Button component={Link} to={"/login"}>Login</Button>
            }
        </Toolbar>
    </AppBar>
}

export default NavBar;