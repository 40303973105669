import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { MapView } from "@aws-amplify/ui-react";
import MarkerWithPopup from "../components/MarkerWithPopup";
import Alert from "@mui/material/Alert";
import "@aws-amplify/ui-react/styles.css";
import { Note } from "../models";
import { API } from "aws-amplify";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { MapRef } from "react-map-gl";
import { listNotes } from "../graphql/queries";
import { deleteNote as deleteNoteMutation } from "../graphql/mutations";
import { ListNotesQuery } from "../API";
import useAuth from "../components/hooks/useAuth";

const TrackingPage: React.FC = () => {
  const [response, setResponse] = useState<Array<Note>>([]);
  const [initialLat, setInitialLat] = useState(53.34);
  const [initialLong, setInitialLong] = useState(-1.49);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState<MapRef | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [nextOpen, setNextOpen] = useState(-1);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const authenticated = useAuth();

  useEffect(() => {
    const loadNotesFunc = async () => {
      let filter: any = {
        owner: {
          eq: params.bugId ?? "",
        },
      };

      if (searchParams.get("date")) {
        filter = {
          and: [
            {
              owner: {
                eq: params.bugId ?? "",
              },
            },
            {
              createdAt: {
                le: searchParams.get("date"),
              },
            },
          ],
        };
      }

      const notesResponse = (await API.graphql({
        query: listNotes,
        authMode: authenticated ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
        variables: {
          filter: filter,
          limit: 200,
        },
      })) as { data: ListNotesQuery };
      const notes = notesResponse.data.listNotes?.items as Array<Note>;
      if (!notes) {
        setLoading(false);
        return;
      }
      //const notes = await DataStore.query(Note, (n) => n.owner("eq", params.bugId ?? ""));
      notes
        .sort((a, b) => {
          if (!a?.createdAt) {
            return 1;
          }

          if (!b?.createdAt) {
            return -1;
          }
          const aTime = new Date(a.createdAt).getTime();
          const bTime = new Date(b.createdAt).getTime();

          return aTime - bTime;
        })
        .reverse();
      if (!notes || notes.length === 0) {
        setShowAlert(true);
      }
      setResponse(notes);
      setLoading(false);
    };
    loadNotesFunc();
  }, [params.bugId, authenticated, searchParams]);

  useEffect(() => {
    if (response.length > 0 && response[0].coords) {
      const lat = response[0].coords.lat;
      const long = response[0].coords.long;
      setInitialLat(lat);
      setInitialLong(long);
    }
  }, [response]);

  const flyTo = (next: number) => {
    if (map !== null) {
      if (next >= response.length || next < 0) {
        return;
      }
      const newLocation = response[next].coords;
      if (!newLocation) {
        return;
      }
      setNextOpen(next);
      map.flyTo({ center: [newLocation.long, newLocation.lat] });
    }
  };

  const deleteNote = async (noteId: string) => {
    await API.graphql({
      query: deleteNoteMutation,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id: noteId } },
    });
    const newResponse = Object.assign([], response);
    newResponse.filter((n: Note) => n.id !== noteId);
    setResponse(newResponse);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item>
        {showAlert && (
          <Alert severity="warning">
            Could not find anything for that Travelbug Name -{" "}
            <Link to="/"> Home</Link>
          </Alert>
        )}
      </Grid>
      <Grid item md={12}>
        {!loading && !showAlert && (
          <MapView
            initialViewState={{
              latitude: initialLat,
              longitude: initialLong,
              zoom: 10,
            }}
            style={{ overflow: "hidden" }}
            reuseMaps={true}
            ref={(e) => {
              setMap(e);
            }}
          >
            {response.map((r, i) => (
              <MarkerWithPopup
                note={r}
                id={i}
                onDelete={deleteNote}
                isFirst={i === response.length - 1}
                flyToNext={flyTo}
                triggerOpen={i === nextOpen}
              />
            ))}
          </MapView>
        )}
      </Grid>
    </Grid>
  );
};

export default TrackingPage;
