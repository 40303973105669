import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";

const useAuth = () => {
    const [auth, setAuth] = useState(false);



    useEffect(() => {
        const isAuthenticated = async () => {
            try {
                await Auth.currentAuthenticatedUser()
                setAuth(true);
            } catch {
                setAuth(false)
            }
        }
        isAuthenticated();
    })

    return auth;
}

export default useAuth;